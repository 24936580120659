//primary colors
$primaryMain: #212121;
$primaryLight: #484848;
$primaryDark: #000000;
$primaryText: #ffffff;
//secondary colors
$secondaryMain: #eeeeee;
$secondaryLight: #ffffff;
$secondaryDark: #bcbcbc;
$secondaryText: #000000;
//Colored mainly for the app-bar-badges
$coloredOrange: #F18D1E;
$coloredGreen: #00FF00;
$primaryMain: #eeeeee;
$primaryLight: #ffffff;
$primaryDark: #bcbcbc;
$primaryText: #000000;
//secondary Colors;
$secondaryMain: #ff8c00;
$secondaryLight: #ffbd45;
$secondaryDark: #c55d00;
$secondaryText: #000000;
$scroll-thumb-color: rgb(185, 183, 183);